import { element } from 'protractor';
import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { DoctorService } from "../../doctor.service";
import { UserService } from "../../user.service";
import { ConfirmationModelComponent } from "../confirmation-model/confirmation-model.component";
import { Observable,Subscription, interval  } from 'rxjs';
import { NotifyhealthworkerService } from '../../notifyhealthworker.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { HealthworkerService } from "../../healthworker/healthworker.service";
import { Lightbox } from 'ngx-lightbox';
import { CroppedEvent } from 'ngx-photo-editor';

@Component({
  selector: "app-current-day-appointments",
  templateUrl: "./current-day-appointments.component.html",
  styleUrls: ["./current-day-appointments.component.css"],
})
export class CurrentDayAppointmentsComponent implements OnInit {
  imageChangedEvent: any;
  base64: any;
  _albums = [];
  enableModal : boolean = false;
  prescriptionsToShow: any = [];
  appointments: any = [];
  isTimeStarted:boolean =false;
  consultationDetail: any;
  private updateSubscription: Subscription;

  prescriptionPictureUpload: boolean = false
  prescriptionCaptured: boolean = false
  public pagingStartIndex: number;
  public pagingEndIndex: number;
  public pageSize: number;
  public numberOfPages: number;
  public pageNum: number[];
  public currentPage: number;
  public displayData: any;
  public newStatus: string;
  public selectedDate: any;
  public today = new Date();
  public patId: string;
  prescriptionImage: any;
  base64Prescription: any;
  formData: any = new FormData();
  sysTotalSeconds: any;
  @Input() appointmentsToday: any;
  snackbarOptions: any = {
    duration: 3000,
    verticalPosition: "bottom",
  };
  loading: boolean = false;

  invoice : any;
  enableInvoiceModal : boolean;

  constructor(private _lightbox: Lightbox,
    private service: UserService,
    private dialog: MatDialog,
    private doctorService: DoctorService,
    private router: Router,
    private snackbar: MatSnackBar,
    private datePipe: DatePipe,
    private notifyhealthworkerService:NotifyhealthworkerService,
    private imageCompress: NgxImageCompressService,
    private healthworkerService:HealthworkerService
  ) {
    this.pagingStartIndex = 0;
    this.displayData = [];
    this.pageNum = [];
    this.pageSize = 10; //default page size
    this.currentPage = 1;
  }

  ngOnInit() {
    this.enableModal = false;
    this.service.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.getTodaysAppointments()
    this.updateSubscription = interval(1000).subscribe((val) => {
      var systemDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      var sysHourInSec = new Date().getHours() * 3600;
      var sysMinInSec = new Date().getMinutes() * 60;

      this.sysTotalSeconds = sysHourInSec + sysMinInSec;
      // console.log(this.sysTotalSeconds);
      if(this.sysTotalSeconds>80000 || this.sysTotalSeconds<25200){
        // console.log('false')
        this.updateSubscription.unsubscribe();
      }
    })
  }


  getTodaysAppointments() {
    this.loading = true
    this.doctorService.getTodayDoctorAppointmentsList(this.service.userDetails.docId).subscribe((res: any) => {
      // console.log(res)
      this.loading = false
      this.appointments = res.response
      console.log(this.appointments)
      this.displayPage(1);
    },
      err => {
        console.log(err)
      })
  }


  /*getTodaysAppointments(){
    this.loading = true;
    this.doctorService
      .getTodayDoctorAppointmentsList(this.service.userDetails.docId)
      .subscribe(
        (res: any) => {
          console.log(res);
          this.loading = false;
          res.response.forEach((element) => {
            let d = this.datePipe.transform(this.today, "MMM dd y");
            let p = this.datePipe.transform(element.appDate, "MMM dd y");
            if (d == p)// &&              element.status != "Dr. Cancelled")
              this.appointments.push(element);
          });
          this.displayPage(1);
        },
        (err) => {
          console.log(err);
        }
      );
  }*/

  openVideoCall(consultation: any) {
    this.doctorService.getDoctorVideocallRoomId(consultation.consultationId).subscribe((res: any) => {
      console.log("Videocall Link", res)
      consultation.callId = res.response.videoLink;
      localStorage.setItem('consultation', JSON.stringify(consultation));
      this.notifyhealthworkerService.notifyhealthworker(consultation.hwId);
      // console.log("Consultation Details")
      // console.log(consultation)
      // console.log("Consultation Details : Hw Id")
      // console.log(consultation.hwId)
      this.router.navigate(['doctor/videoCall']);
      window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(JSON.stringify({ "backButtonDisabled": false, "callStarted": true, "path": "confirmtion-modal" }));
      // console.log("notifyReact")
      this.router.navigate(['doctor/videoCall']);
    },
      err => {
        console.log(err)
      })
  }

  openDashboard(consultation: any) {
    localStorage.setItem("consultation", JSON.stringify(consultation));
    this.service.patient = consultation;
    this.router.navigate(["doctor/healthRecords"]);
    window["ReactNativeWebView"] &&
      window["ReactNativeWebView"].postMessage(
        JSON.stringify({  "backButtonDisabled": false,"change": true,"path":"openPatientProfile" })
      );
    console.log("notifyReact");

    // let dialogRef = this.dialog.open(ConfirmationModelComponent);
    // dialogRef.componentInstance.consultationDetail = consultation;
  }

  public displayPage(pageNumber: number) {
    this.pagingStartIndex = this.pageSize * (pageNumber - 1);
    this.pagingEndIndex =
      Number.parseInt(this.pagingStartIndex.toString()) +
      Number.parseInt(this.pageSize.toString()) -
      1;
    this.displayData = [];
    this.pageNum = [];
    let mod: number = this.appointments.length % this.pageSize;
    if (mod > 0)
      this.numberOfPages =
        Math.floor(this.appointments.length / this.pageSize) + 1;
    else this.numberOfPages = this.appointments.length / this.pageSize;

    console.log("Numberofpages" + this.numberOfPages);

    for (let i = 0; i < this.numberOfPages; i++) {
      this.pageNum.push(i + 1);
    }

    //update the data to be displayed based on above changes
    for (let i = this.pagingStartIndex; i <= this.pagingEndIndex; i++) {
      if (this.appointments[i] != null) {
        this.displayData.push(this.appointments[i]);
      }
    }

    //update current page
    this.currentPage = pageNumber;
  }

  confirmAppointment(consultationId: any, index: any) {
    this.doctorService
      .updateAppointmentStatus(consultationId, "CONFIRMED")
      .subscribe((res: any) => {
        console.log(res);
        if (res.responseCode == "200") {
          this.snackbar.open(res.message, "", this.snackbarOptions);
          this.displayData[index].status = "CONFIRMED";
        }
      });
  }

  rejectAppointment(consultationId: any, index: any) {
    console.log("REJECTING");
    this.loading = true;
    this.doctorService
      .rejectAppointment(consultationId, "Dr. Cancelled")
      .subscribe((res: any) => {
        this.loading = false;
        if (res.responseCode == "200") {
          this.displayData[index].status = "Dr. Cancelled";
        }
        this.snackbar.open(res.message, "", this.snackbarOptions);

      },error=>{
        this.loading = false;
      });
  }

  cancelAppontment(event:any){
    if(event){
      this.rejectAppointment(event.consultationId,event.index);
    }
  }

  prescriptionUpload(e, index, consultationId,patId){
    this.patId = patId;
    this.prescriptionImage = e.target.files[0]
    let size = e.target.files[0].size / (1024 * 1024)
    console.log(this.prescriptionImage)
    if(this.prescriptionImage!=null){
      if(size>15)
          this.snackbar.open("File size can't be more than 15 mb!!", '',{
            duration: 3000,
            verticalPosition: 'bottom'
          })
      else{
        this.convertToBase64(this.prescriptionImage,consultationId, index)
      }
    }
  }

  convertToBase64(file: File, consultationId: any, index: number){
    const reader: FileReader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = ()=> {
      this.base64Prescription = reader.result
      this.submitNewPrescriptionAndClinicalNotes(consultationId, index)
    }
  }


  submitNewPrescriptionAndClinicalNotes(consultationId: any, index: number) {
    this.loading = true
    this.formData = new FormData()
    let p = this.base64Prescription.replace(/^data:image\/[a-z]+;base64,/, "")
    console.log('Size before compression' + this.prescriptionImage.size)
    /*this.imageCompress.compressFile(this.base64Prescription, 50, 50).then(
      result => {
        console.warn('Size in bytes is now:', this.imageCompress.byteCount(result))
        let p = result.replace(/^data:image\/[a-z]+;base64,/, "")
        const imageBlob = this.dataURItoBlob(p)
        const imageFile = new File([imageBlob], this.prescriptionImage.name, { type: 'image/jpg' })
        this.formData.append('clinicalNotePic', imageFile)
        this.callPrescriptionUploadApi(consultationId, index)
    })*/

    const imageBlob = this.dataURItoBlob(p)
    const imageFile = new File([imageBlob], this.prescriptionImage.name, { type: 'image/jpg' })
    this.formData.append('clinicalNotePic', imageFile)
    this.callPrescriptionUploadApi(consultationId, index)

  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }


  callPrescriptionUploadApi(consultationId: any, index: number){
    this.doctorService.addPrescriptionAndClinicalNotes(this.formData, this.patId, consultationId).subscribe((res: any) => {
      console.log(res)
      this.loading = false
      if (res.responseCode == "201") {
        //this.oldPrescriptionList.push(res.response)
        // this.getOldPrescriptionList()
        this.prescriptionPictureUpload = true
        this.prescriptionCaptured = false
        this.formData = new FormData()
        this.snackbar.open("Prescription Submitted!!!", '', {
          duration: 3000,
          verticalPosition: 'bottom'
        })
        //this.displayData.remove(index);
       // this.getTodaysAppointments()
      }
    },
      err => {
        this.loading = false
        console.log(err)
      })
  }
/*
  getVideoWarning(startTime:string , endTime:string){
    let graceTime : number  = Number((Number((Number(endTime) - Number(startTime))) / 60))/3;
    console.log("Start Time : ", startTime, " End Time : ", endTime, " Grace Time : ", graceTime);
    this.dialog.open(AlertDialogComponent , {
      data: {title: "Call Alert", msg: "Call will be activated only "+ parseInt(graceTime.toString()) +" mins before Appointment time."}
      //data: {title: "Call Alert", msg: "Call will be activated only 10 mins before Appointment time."}
    });
  }*/

  getVideoWarning(startTime: string, endTime: string) {
    //let graceTime : number  = Number((Number((Number(currentTime) - Number(startTime))) / 60))/3;
    var systemDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    //var sysHourInSec = new Date().getHours() * 3600;
    //var sysMinInSec = new Date().getMinutes() * 60;
    //this.sysTotalSeconds = sysHourInSec + sysMinInSec;
    let diffTime : number  = Number((Number(endTime) - Number(startTime))) / (60*3);    
    let graceTime: number = Number(startTime) - Number(this.sysTotalSeconds);
    console.log("Start Time : ", startTime, " End Time : ", endTime, " SystemTime : ", this.sysTotalSeconds, 
    " Grace Time : ", graceTime, "Diff Time : ", diffTime);
    //graceTime  = Number(currentTime) - Number(this.sysTotalSeconds);
    //console.log("Start Time : ", startTime, " Current Time : ", currentTime, " Grace Time : ", graceTime);
    if (graceTime >= 0) {
      this.dialog.open(AlertDialogComponent, {
        data: {title: "Call Alert", msg: "Call will be activated "+diffTime+" mins before Appointment time."}
        //data: { title: "Call Alert", msg: "Call will be activated only 10 mins before Appointment time." }
      });
    }
  }

  showPrescription(data:any){
    this.service.patient=data;
    this.router.navigate(['/doctor/prescription']);
  }

  showPrescription1(data : any):void{
    console.log(data);
    this.loading = true;

    this.doctorService.getOldPrescriptions(data.patId).subscribe((res: any) => {
      console.log(res);
      this.loading = false
      if (res.responseCode == "200" || res.responseCode == "201") {
        this.prescriptionsToShow = res.response;
        if(this.prescriptionsToShow != null){

          for (let i = 0; i < this.prescriptionsToShow.length; i++) {
            const src = 'data:image/jpg;base64,' + this.prescriptionsToShow[i].imageContents;
            let caption = '';
            if(this.prescriptionsToShow[i].fileName == null){
               caption = 'Prescription_' + i;
            }else{
              caption = this.prescriptionsToShow[i].fileName;
            }

            const thumb = 'data:image/jpg;base64,' + this.prescriptionsToShow[i].imageContents;
            const album = {
               src: src,
               caption: caption,
               thumb: thumb
            };

            this._albums.push(album);
          }

        }
      }
      this.loading = false;
      this.enableModal = true;
    },
      err => {
        this.loading = false
        console.log(err)
      })
  }
  closePrescription():void{
    this.enableModal = false;
  }

  showCancelAppoinmentButton(status, differencetime): boolean {
    if(differencetime>1500 && status == 'CONFIRMED')
    return true;
    else
    return false;
  }

  getInvoice(consultationId:any){

    // this.loading = true;
     // this.healthworkerService.getInvoice(consultationId).subscribe((res: any)=>{
     //   console.log(res)
     //   this.invoice = res.response
     //   this.loading = false
     //   this.enableInvoiceModal = true;
     // });
     localStorage.setItem("InvConsltID",consultationId);
       this.router.navigate(['/doctor/invoice']);
   }

  closeInvoice(){
    this.enableInvoiceModal = false;
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  fileChangeEvent(event: any) {
    // console.log(event);

     this.imageChangedEvent = event;
     //this.imageChangedEvent = event.target.files[0];
     //this.showPopup = true;
   }

   imageCropped(e: CroppedEvent, index, consultationId,patId) {
     console.log(e);
     this.patId = patId;
     this.base64 = e.base64;
     this.prescriptionImage = e.file;
     let size = e.file.size  / (1024 * 1024)
     console.log(this.prescriptionImage)

     if(this.prescriptionImage!=null){
       if(size>15)
           this.snackbar.open("File size can't be more than 15 mb!!", '',{
             duration: 3000,
             verticalPosition: 'bottom'
           })
       else{
         this.convertToBase64(this.prescriptionImage,consultationId, index)
       }
     }


     //this.showPopup = false;
     //this.base64 = event.base64;
     //this.imageChangedEvent = '';
   }
}

